import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { css, styled } from 'styled-components'
import { useDebounceValue } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { ButtonPrimary } from '../../../components/button/ButtonPrimary'
import { FilterTypes } from '../../../components/filters/Filters'
import { DateInput } from '../../../components/form/date-input/DateInput'
import { MultiSelect } from '../../../components/form/MultiSelect'
import { SearchInput } from '../../../components/form/SearchInput'
import { Select } from '../../../components/form/Select'
import { Toggle } from '../../../components/form/Toggle'

import type { Filter } from '../../../components/filters/Filters'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { PropsWithChildren } from 'react'
import type { DateRange } from 'types'

type FilterListProps = {
  filters?: Filter[]
  filterValues: Record<string, unknown>
  onFiltersChange: (filterKey: string, value: unknown) => void
  onClearFilters: () => void
  withSearch?: boolean
  searchPlaceholder?: string
  loading?: boolean
  stickyPosition?: number
  align?: 'left' | 'right'
} & PropsWithChildren

export const Filters = ({
  filters,
  filterValues,
  onFiltersChange,
  onClearFilters,
  withSearch,
  searchPlaceholder,
  loading = false,
  stickyPosition,
  children,
  align = 'left',
}: FilterListProps) => {
  const { t } = useTranslation()

  const [searchParameters, setSearchParameters] = useSearchParams()

  const hasFilters = Object.keys(filterValues).some((key) => {
    if (key === 'search') {
      return filterValues.search && filterValues.search !== ''
    }

    // Only check for filters that are in the filter list
    // Additional filters can be set but shouldn't be influenced by the clear filter button
    if (!filters?.some((filter) => filter.key === key)) {
      return false
    }

    const value = filterValues[key]

    let hasNullValues = false
    let isEmpty = !value

    if (Array.isArray(value)) {
      hasNullValues = value.includes('NULL')
      isEmpty = value.length === 0
    }

    return !isEmpty && !hasNullValues
  })

  const [searchValue, setSearchValue] = useState<string | undefined>(
    filterValues.search as string | undefined
  )
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue<
    string | undefined
  >(undefined, 300)

  const clearSearch = () => {
    onFiltersChange('search', undefined)
    setSearchValue(undefined)
    setDebouncedSearchValue(undefined)
  }

  useEffect(() => {
    if (debouncedSearchValue !== filterValues.search) {
      onFiltersChange('search', debouncedSearchValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue])

  useEffect(() => {
    if (searchParameters.get('dateFrom')) {
      onFiltersChange(
        'dateFrom',
        new Date(searchParameters.get('dateFrom') ?? '')
      )
    }

    if (searchParameters.get('dateTo')) {
      onFiltersChange('dateTo', new Date(searchParameters.get('dateTo') ?? ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // -- Render --
  if (!filters || filters.length === 0) {
    return null
  }

  if (loading) {
    return (
      <StContainer $spread={withSearch} $align={align} $withSearch={withSearch}>
        {withSearch && (
          <div key="loader_search">
            <ContentLoader
              speed={2}
              height={52}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              style={{ width: '100%' }}
            >
              <rect x="0" y="0" rx="4" ry="4" width="100%" height="52" />
            </ContentLoader>
          </div>
        )}
        {filters &&
          filters?.length > 0 &&
          Array.from({ length: filters.length }).map((_, index) => (
            <ContentLoader
              key={`loader_${index}`}
              speed={2}
              width={189}
              height={52}
              viewBox="0 0 189 52"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="4" ry="4" width="188" height="52" />
            </ContentLoader>
          ))}
      </StContainer>
    )
  }

  return (
    <StContainer
      $spread={withSearch}
      $stickyPosition={stickyPosition}
      $withSearch={withSearch}
    >
      {withSearch && (
        <SearchInput
          key="search"
          name="search"
          value={searchValue ?? ''}
          onChange={(value: string) => {
            setSearchValue(value)
            setDebouncedSearchValue(value)
          }}
          placeholder={searchPlaceholder}
        />
      )}

      {hasFilters ? (
        <StButton
          onClick={() => {
            onClearFilters()
            clearSearch()
          }}
          compact
          icon={['fasr', 'xmark']}
        >
          {t('filters.clear')}
        </StButton>
      ) : null}

      {filters && filters?.length > 0
        ? filters.map((filter) => {
            if (filter.type === FilterTypes.Option && filter.options) {
              return (
                <Select
                  key={filter.key}
                  options={filter.options}
                  label={filter.label}
                  placeholder={filter.placeholder}
                  value={filterValues[filter.key] as string | undefined}
                  onChange={(value: unknown) => {
                    onFiltersChange(filter.key, value)
                  }}
                  disabled={filter.disabled}
                />
              )
            } else if (filter.type === FilterTypes.Multi && filter.options) {
              return (
                <MultiSelect
                  key={filter.key}
                  options={filter.options}
                  label={filter.label}
                  placeholder={filter.placeholder}
                  value={filterValues[filter.key] as string[]}
                  onChange={(value: unknown) => {
                    onFiltersChange(filter.key, value)
                  }}
                  disabled={filter.disabled}
                />
              )
            } else if (filter.type === FilterTypes.Toggle) {
              return (
                <Toggle
                  key={filter.key}
                  icon={
                    (filter.icon || ['fasr', 'circle-exclamation']) as IconProp
                  }
                  color={filter.color}
                  active={!!filterValues[filter.key]}
                  onToggle={(active) => {
                    onFiltersChange(filter.key, active)
                  }}
                />
              )
            } else if (filter.type === FilterTypes.DateRange) {
              return (
                <DateInput
                  key={filter.key}
                  onChange={(value) => {
                    onFiltersChange('dateFrom', (value as DateRange).start)
                    onFiltersChange('dateTo', (value as DateRange).end)

                    setSearchParameters((previous) => {
                      previous.set(
                        'dateFrom',
                        (value as DateRange).start?.toISOString() ?? ''
                      )
                      previous.set(
                        'dateTo',
                        (value as DateRange).end?.toISOString() ?? ''
                      )

                      return previous
                    })
                  }}
                  shortCutType={filter.shortCutType || 'month'}
                  range={true}
                  value={{
                    start: filterValues.dateFrom as Date | null,
                    end: filterValues.dateTo as Date | null,
                  }}
                  placeholder={filter.placeholder}
                  label={filter.label}
                  disabled={filter.disabled}
                  formatString="MMM d"
                />
              )
            } else {
              return null
            }
          })
        : null}
      {children}
    </StContainer>
  )
}

const StContainer = styled.div<{
  $spread?: boolean
  $stickyPosition?: number
  $align?: 'left' | 'right'
  $withSearch?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  background-color: white;

  ${({ $stickyPosition }) =>
    $stickyPosition &&
    css`
      position: sticky;
      top: ${$stickyPosition}px;
      padding-top: ${({ theme }) => theme.UI.SpacingPx.Space3};
      // We need a z-index higher than the table header
      z-index: 10;
    `}

  > * {
    flex-shrink: 0;

    &:first-child {
      ${({ $withSearch, $spread }) =>
        $withSearch &&
        css`
          flex-basis: 0;
          flex-grow: ${() => ($spread ? 1 : 0)};
        `}
    }
  }

  ${({ $align }) =>
    $align === 'right' &&
    css`
      justify-content: flex-end;
    `}

  @media ${breakpoints.desktop} {
    flex-wrap: nowrap;
  }
`

const StButton = styled(ButtonPrimary)`
  padding-top: 26px;
  padding-bottom: 26px;
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
`
