import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { minutesToHoursAndMinutes } from 'utils/src'

import { breakpoints } from '../../../theme/layout/breakpoints'
import { applyVat, formatDecimals } from '../../core/utils/number'
import {
  formatCurrency,
  formatPowerConsumption,
} from '../../employer/utils/statistics'
import { useUser } from '../../user/hooks/useUser'
import {
  BodySmallMedium,
  BodySmallRegularCss,
  BodySmallSemiBold,
} from '../typography'

import { PriceBreakdownItem } from './PriceBreakdownItem'

import type { ChargingSessionWithRelations } from 'types'

type PriceBreakdownProps = {
  chargingSession: ChargingSessionWithRelations
}

export const PriceBreakdown = ({ chargingSession }: PriceBreakdownProps) => {
  // Hooks
  const { t } = useTranslation()
  const { user } = useUser()

  // Price breakdown
  const totalCost = useMemo(() => {
    return chargingSession.type === 'Home'
      ? chargingSession.hcpPrice
      : applyVat(chargingSession.mspPrice, chargingSession.vat)
  }, [
    chargingSession.hcpPrice,
    chargingSession.mspPrice,
    chargingSession.vat,
    chargingSession.type,
  ])

  // No VAT for home charging sessions
  const vatPercentage =
    chargingSession.type === 'Home' ? 0 : chargingSession.vat

  const startingPriceLine = chargingSession.chargingSessionLines
    .filter(
      (line) =>
        line.sessionOrigin === (chargingSession.type === 'Home' ? 'HCP' : 'MSP')
    )
    .find((line) => line.type === 'Start')
  const startingPrice = applyVat(startingPriceLine?.price ?? 0, vatPercentage)
  const hasStartingPrice = startingPrice > 0

  const energyPrices = chargingSession.chargingSessionLines.filter(
    (line) =>
      line.sessionOrigin ===
        (chargingSession.type === 'Home' ? 'HCP' : 'MSP') &&
      line.type === 'Energy'
  )
  const totalEnergyPrice = applyVat(
    energyPrices.reduce((accumulator, price) => accumulator + price.price, 0),
    vatPercentage
  )
  const energyPriceText = (() => {
    const initialText = t('map.detail.group.tarrif-kwh', {
      currency: '',
      price: formatCurrency(
        applyVat(energyPrices[0].tariff ?? 0, vatPercentage),
        user.language
      ),
    })

    if (energyPrices.every((price) => price.price === 0)) {
      return (
        <PriceBreakdownItem
          label={t('map.detail.group.charging-price')}
          value={
            chargingSession.type === 'Work' ? (
              <StNvt>{t('employee.chargingSessions.detail.not-tracked')}</StNvt>
            ) : (
              '€ 0'
            )
          }
          subLabel={`${formatPowerConsumption(
            chargingSession.kwh,
            2,
            user.language
          )}`}
          icon={['fass', 'bolt']}
        />
      )
    }

    // If no limit return initial price
    if (!energyPrices.some((price) => price.limit)) {
      return (
        <PriceBreakdownItem
          label={t('map.detail.group.charging-price')}
          value={formatCurrency(totalEnergyPrice, user.language)}
          subLabel={`${formatPowerConsumption(
            chargingSession.kwh,
            2,
            user.language
          )}`}
          subValue={initialText}
          icon={['fass', 'bolt']}
        />
      )
    }

    // Otherwise calculate additional prices
    return energyPrices.map((price, index) => {
      const start = index === 0 ? 0 : energyPrices[index - 1].limit
      const text = t('map.detail.group.tarrif-kwh', {
        currency: '',
        price: formatCurrency(
          applyVat(price.tariff ?? 0, vatPercentage),
          user.language
        ),
      })

      return (
        <PriceBreakdownItem
          key={index}
          label={
            price.limit
              ? `${t('map.detail.group.charging-price')} (${start}-${
                  price.limit
                } kWh)`
              : `${t('map.detail.group.charging-price')} (${start}+ kWh)`
          }
          value={`${formatCurrency(
            applyVat(price.price, vatPercentage),
            user.language
          )}`}
          subLabel={`${price.usage} kWh`}
          subValue={text}
          icon={['fass', 'bolt']}
        />
      )
    })
  })()

  const hourPrices = chargingSession.chargingSessionLines.filter(
    (line) =>
      line.sessionOrigin ===
        (chargingSession.type === 'Home' ? 'HCP' : 'MSP') &&
      line.type === 'Time'
  )
  const hourPriceText = (() => {
    if (hourPrices.every((price) => price.price === 0)) return ''

    const initialPrice = formatCurrency(
      applyVat(hourPrices[0].price, vatPercentage),
      user.language
    )

    // If no limit return initial price
    if (!hourPrices.some((price) => price.limit)) {
      return (
        <StPriceWrapper>
          <BodySmallSemiBold>
            {t('map.detail.group.hourly-price')}
          </BodySmallSemiBold>
          <BodySmallSemiBold>{`${initialPrice}`}</BodySmallSemiBold>
        </StPriceWrapper>
      )
    }

    return hourPrices.map((price, index) => {
      const start = (index === 0 ? 0 : hourPrices[index - 1].limit ?? 0) / 60
      const pricePerHour = formatCurrency(
        applyVat(price.tariff ?? 0, vatPercentage),
        user.language
      )
      const text = t('map.detail.group.tarrif-hour', {
        currency: '€',
        price: pricePerHour,
      })

      const duration = minutesToHoursAndMinutes((price.usage ?? 0) / 60)

      return (
        <PriceBreakdownItem
          key={index}
          label={
            price.limit
              ? `${t('map.detail.group.hourly-price')} (${start}-${
                  (price.limit ?? 0) / 60
                } min)`
              : `${t('map.detail.group.hourly-price')} (${start}+ min)`
          }
          value={`${formatCurrency(
            applyVat(price.price, vatPercentage),
            user.language
          )}`}
          subLabel={`${
            duration.hours > 0 &&
            `${t('employee.chargingSessions.detail.duration-hours', {
              hours: duration.hours,
            })} `
          }
              ${t('employee.chargingSessions.detail.duration-minutes', {
                minutes: formatDecimals(duration.minutes, 0),
              })}`}
          subValue={text}
          icon={['fasr', 'clock']}
        />
      )
    })
  })()

  return (
    <StChargingSessionInfo>
      <StPricesWrapper>
        {hasStartingPrice && (
          <StPriceWrapper>
            <BodySmallSemiBold>
              {t('map.detail.group.start-price')}
            </BodySmallSemiBold>
            <BodySmallSemiBold>
              {formatCurrency(startingPrice, user.language)}
            </BodySmallSemiBold>
          </StPriceWrapper>
        )}
        {energyPriceText}
        {hourPriceText}
        <StPriceTotal>
          <StPriceWrapper>
            <BodySmallSemiBold>
              {t('employee.chargingSessions.detail.total')}
            </BodySmallSemiBold>
            {chargingSession.type === 'Work' ? (
              <StNvt>{t('employee.chargingSessions.detail.not-tracked')}</StNvt>
            ) : (
              <BodySmallSemiBold>
                {formatCurrency(totalCost, user.language)}
              </BodySmallSemiBold>
            )}
          </StPriceWrapper>
        </StPriceTotal>
      </StPricesWrapper>
    </StChargingSessionInfo>
  )
}

const StChargingSessionInfo = styled.div`
  ${BodySmallRegularCss}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media ${breakpoints.desktop} {
    padding-top: 0;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`

const StPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StPricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

const StPriceTotal = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

const StNvt = styled(BodySmallMedium)`
  color: ${({ theme }) => theme.theme.text.body['gray-mid']};
`
