import type { SelectOption } from '../form/Select'

export enum FilterTypes {
  Option = 'option',
  Multi = 'multi-option',
  Toggle = 'toggle',
  DateRange = 'date-range',
}

export type Filter = {
  key: string
  type: FilterTypes
  label?: string
  placeholder?: string
  options?: SelectOption[]
  defaultValue?: unknown
  disabled?: boolean
  shortCutType?: 'month' | 'short-term' | 'relative'
  icon?: [string, string]
  color?: string
}
