import { endOfDay, lastDayOfMonth, setDate, startOfDay, format } from 'date-fns'
import { useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from 'styled-components'

import { Urls } from '../../../../api/urls'
import { Drawer, DrawerContent } from '../../../../components/general/Drawer'
import { useGetDateFnsLocale } from '../../../../core/hooks/useGetDateFnsLocale'
import { SortKeys } from '../../../../dataTable/components/DataTableHeader'
import {
  DataTableProvider,
  PaginationType,
} from '../../../../dataTable/providers/DataTableProvider'

import { EmployeeDetailChargingSessionInfo } from './EmployeeDetailChargingSessionInfo'
import { EmployeeDetailUsageDataTable } from './EmployeeDetailUsageDataTable'

import type {
  ChargingSessionWithDetectedAnomalies,
  UserWithContractAndCards,
} from 'types'

type EmployeeDetailUsageProps = {
  employee: UserWithContractAndCards
}

export const EmployeeDetailUsage = ({ employee }: EmployeeDetailUsageProps) => {
  // Shared drawer state
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedSession, setSelectedSession] =
    useState<ChargingSessionWithDetectedAnomalies | null>(null)
  const dateFnsLocale = useGetDateFnsLocale()
  const location = useLocation()
  const { state } = location
  const sessionProcessed = useRef(false)

  const handleSessionClick = (
    session: ChargingSessionWithDetectedAnomalies
  ) => {
    setSelectedSession(session)
    setIsDrawerOpen(true)
  }

  const handleSessionFound = (
    session: ChargingSessionWithDetectedAnomalies | undefined
  ) => {
    if (!sessionProcessed.current && session) {
      sessionProcessed.current = true
      setSelectedSession(session)
      setIsDrawerOpen(true)
    }
  }

  return (
    <StContainer>
      {/* Usage Section */}
      <DataTableProvider
        paginationType={PaginationType.Infinite}
        url={Urls.employeeChargingSessions + `?employeeId=${employee.id}`}
        defaultSort="start"
        defaultSortDirection={SortKeys.DESCENDING}
        initialFilterValues={{
          dateFrom: startOfDay(setDate(new Date(), 1)),
          dateTo: endOfDay(lastDayOfMonth(new Date())),
        }}
        standaloneFilterKeys={['dateFrom', 'dateTo']}
        amount={30}
      >
        <EmployeeDetailUsageDataTable
          employee={employee}
          onRowClick={(session: ChargingSessionWithDetectedAnomalies) => {
            handleSessionClick(session)
          }}
          openSessionId={state?.chargingSessionId}
          onSessionFound={handleSessionFound}
        />
      </DataTableProvider>

      {selectedSession && (
        <Drawer open={isDrawerOpen}>
          <DrawerContent
            onClose={() => setIsDrawerOpen(false)}
            title={format(
              new Date(selectedSession.start),
              'dd MMMM yyyy',
              dateFnsLocale
            )}
          >
            <EmployeeDetailChargingSessionInfo
              chargingSession={selectedSession}
            />
          </DrawerContent>
        </Drawer>
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`
