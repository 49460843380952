import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { useSWRConfig } from 'swr'

import { LocalStorageKeys } from '../../../../../config/constants/local-storage-keys'
import { replaceParametersInPath } from '../../../../../routing/lib/replaceIdInPath'
import { EmployerRoutes } from '../../../../../routing/routes'
import { EventType, useTracking } from '../../../../analytics/hooks/useTracking'
import { useAuthMemberAxios } from '../../../../api/hooks/useAuthMemberAxios'
import { Urls } from '../../../../api/urls'
import { DetailPageHeader } from '../../../../components/general/DetailPageHeader'
import { Menubar } from '../../../../components/general/Menubar'
import { ContentContainer } from '../../../../components/page-layout'
import { StatusChip } from '../../../../components/status-chip/StatusChip'
import { Tabs } from '../../../../components/Tabs/Tabs'
import {
  BodyMediumRegular,
  BodyMediumSemiBold,
} from '../../../../components/typography'
import { useMember } from '../../../../member/hooks/useMember'
import { isUrgent } from '../../../../quotes/lib/quote'

import { DeleteEmployeeDialog } from './DeleteEmployeeDialog'
import { EmployeeDetailInformation } from './EmployeeDetailInformation'
import { EmployeeDetailUsage } from './EmployeeDetailUsage'
import { FreezeEmployeeDialog } from './FreezeEmployeeDialog'
import { UnfreezeEmployeeDialog } from './UnfreezeEmployeeDialog'

import type { MenubarItems } from '../../../../components/general/Menubar'
import type { AxiosPromise } from 'axios'
import type { User } from 'database'
import type { UserWithContractAndCards, UserWithMembers } from 'types'

type EmployeeDetailProps = {
  employee: UserWithContractAndCards
  updateEmployee: (data: Partial<User>) => AxiosPromise<UserWithMembers>
}

type ImpersonateResponse = {
  membershipId: string
}

export const EmployeeDetail = ({
  employee,
  updateEmployee,
}: EmployeeDetailProps) => {
  // -- State --
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [freezeDialogOpen, setFreezeDialogOpen] = useState(false)
  const [unfreezeDialogOpen, setUnfreezeDialogOpen] = useState(false)
  // -- Hooks --
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const navigate = useNavigate()
  const { currentMember, isCurrentMemberSuperAdmin } = useMember()
  const [searchParameters, setSearchParameters] = useSearchParams()

  const { mutate } = useSWRConfig()

  // -- Vars --
  const [currentTab, setCurrentTab] = useState(
    searchParameters.get('tab') ?? 'information'
  )

  // -- Data --
  const refetchEmployee = () => {
    mutate(
      replaceParametersInPath(Urls.employeeDetail, {
        blossomId: employee.blossomId,
      }),
      undefined,
      { revalidate: true }
    )
  }

  const [, deleteEmployee] = useAuthMemberAxios(
    {
      url: replaceParametersInPath(Urls.employeeDelete, { id: employee.id }),
      method: 'DELETE',
    },
    { manual: true }
  )

  const [{ loading: isFreezing }, freezeEmployee] = useAuthMemberAxios(
    {
      url: replaceParametersInPath(Urls.employeeFreeze, { id: employee.id }),
      method: 'POST',
    },
    { manual: true }
  )

  const [{ loading: isUnfreezing }, unfreezeEmployee] = useAuthMemberAxios(
    {
      url: replaceParametersInPath(Urls.employeeUnfreeze, { id: employee.id }),
      method: 'POST',
    },
    { manual: true }
  )
  const [, markEmployeeForManualHandover] = useAuthMemberAxios(
    {
      url: replaceParametersInPath(Urls.employeeManualMspHandover, {
        memberId: employee.members[0].id,
      }),
      method: 'POST',
    },
    { manual: true }
  )

  const [, resendEmployeeInvite] = useAuthMemberAxios(
    {
      url: Urls.employeeResendInvite,
      method: 'POST',
      data: { userId: employee.id },
    },
    { manual: true }
  )

  const [, impersonateEmployee] = useAuthMemberAxios<ImpersonateResponse>(
    {
      url: replaceParametersInPath(Urls.impersonateEmployee, {
        employeeId: employee.members[0].id,
      }),
      method: 'PATCH',
    },
    { manual: true }
  )

  // -- Effects --
  // If the currentMember changes, we navigate back to the employees overview
  // This employee will not exist on the new member so it makes no sense to stay on the detail page
  useEffect(() => {
    if (employee.members[0]?.companyId !== currentMember.companyId) {
      navigate(EmployerRoutes.Employees)
    }
  }, [currentMember.companyId, currentMember.id, employee.members, navigate])

  // -- Vars --
  const detailPages = [
    {
      content: (
        <EmployeeDetailInformation
          employee={employee}
          updateEmployee={updateEmployee}
          refetchEmployee={refetchEmployee}
        />
      ),
      value: 'information',
      title: t('admin.employee-detail.information'),
    },
    {
      content: <EmployeeDetailUsage employee={employee} />,
      value: 'usage',
      title: t('admin.employee-detail.usage'),
    },
  ]

  const handleImpersonate = async () => {
    // Extra safety check. Should never happen.
    if (!isCurrentMemberSuperAdmin) {
      toast.error('Action not allowed')
      return
    }

    try {
      const { data } = await impersonateEmployee()

      if (data?.membershipId) {
        localStorage.setItem(
          LocalStorageKeys.CURRENT_MEMBER,
          JSON.stringify(data.membershipId)
        )
        toast.success(
          t('admin.employee-detail.actions.impersonate.success', {
            employeeName: employee.fullName,
          })
        )
        window.location.reload()
      } else {
        toast.error(t('admin.employee-detail.actions.impersonate.error'))
      }
    } catch {
      toast.error(t('admin.employee-detail.actions.impersonate.error'))
    }
  }

  const menuItem: MenubarItems = [
    {
      name: t('admin.employee-detail.actions.delete'),
      onClick: () => setDeleteDialogOpen(true),
    },
    employee.members[0].frozenAt
      ? {
          name: t('admin.employee-detail.actions.unfreeze'),
          onClick: () => setUnfreezeDialogOpen(true),
        }
      : {
          name: t('admin.employee-detail.actions.freeze'),
          onClick: () => setFreezeDialogOpen(true),
        },
    employee.members[0].mspStatus === 'Requested' ||
    employee.members[0].mspStatus === 'Invited' ||
    employee.members[0].mspStatus === 'Registered'
      ? {
          name: t('admin.employee-detail.actions.manual-handover'),
          onClick: () => handleManualHandover(),
        }
      : undefined,
    employee.hcpStatus?.status === 'Invited' ||
    employee.members[0].mspStatus === 'Invited'
      ? {
          name: t('admin.employee-detail.actions.resend-invite'),
          onClick: () => handleResendEmployeeInvite(),
        }
      : undefined,
    employee && !employee.deletedAt && isCurrentMemberSuperAdmin
      ? {
          name: t('admin.employee-detail.actions.impersonate'),
          onClick: handleImpersonate,
        }
      : undefined,
  ].filter(Boolean) as MenubarItems

  // -- Handlers --
  const handleDelete = async (reason: string) => {
    trackEvent(EventType.Submit, 'confirm_delete_member', {
      userId: employee.id,
    })

    try {
      await deleteEmployee({
        params: {
          reason,
        },
      })

      navigate(EmployerRoutes.Employees)
      toast.success(t('admin.employee-detail.actions.delete.success'))
    } catch {
      toast.error(t('admin.employee-detail.actions.delete.error'))
    }
  }

  const handleFreeze = async (reason: string) => {
    trackEvent(EventType.Submit, 'freeze_member', {
      userId: employee.id,
    })

    try {
      mutate(
        replaceParametersInPath(Urls.employeeDetail, {
          blossomId: employee.blossomId,
        }),
        freezeEmployee({
          data: {
            reason,
          },
        }),
        {
          optimisticData: {
            ...employee,
            members: employee.members.map((member) => ({
              ...member,
              frozenAt: new Date(),
              freezeReason: reason,
            })),
          },
          revalidate: false,
          populateCache: false,
        }
      )

      setFreezeDialogOpen(false)
      toast.success(t('admin.employee-detail.actions.freeze.success'))
    } catch {
      toast.error(t('admin.employee-detail.actions.freeze.error'))
    }
  }

  const handleUnfreeze = async () => {
    trackEvent(EventType.Submit, 'unfreeze_member', {
      userId: employee.id,
    })

    try {
      mutate(
        replaceParametersInPath(Urls.employeeDetail, {
          blossomId: employee.blossomId,
        }),
        unfreezeEmployee(),
        {
          optimisticData: {
            ...employee,
            members: employee.members.map((member) => ({
              ...member,
              frozenAt: null,
              mspStatus: 'Active',
            })),
          },
          revalidate: false,
          populateCache: false,
        }
      )

      setUnfreezeDialogOpen(false)
      toast.success(t('admin.employee-detail.actions.unfreeze.success'))
    } catch {
      toast.error(t('admin.employee-detail.actions.unfreeze.error'))
    }
  }

  const handleManualHandover = async () => {
    trackEvent(EventType.Submit, 'manual_handover', {
      userId: employee.id,
    })

    try {
      await markEmployeeForManualHandover()

      refetchEmployee()
      toast.success(t('admin.employee-detail.actions.manual-handover.success'))
    } catch {
      toast.error(t('admin.employee-detail.actions.manual-handover.error'))
    }
  }

  const handleResendEmployeeInvite = async () => {
    trackEvent(EventType.Submit, 'resend_invite', {
      userId: employee.id,
    })

    try {
      await resendEmployeeInvite()

      refetchEmployee()
      toast.success(t('admin.employee-detail.actions.resend-invite.success'))
    } catch {
      toast.error(t('admin.employee-detail.actions.resend-invite.error'))
    }
  }

  // -- Render --
  return (
    <ContentContainer>
      <StContainer>
        <StHeader>
          <StTitleContainer>
            <DetailPageHeader
              title={employee.fullName || employee.workEmail || ''}
              topTitle={t('admin.employees.title')}
            />
            <div>
              {employee.members[0].deletedAt ? (
                <div>
                  <BodyMediumRegular>
                    {t('admin.employee-detail.status')}
                  </BodyMediumRegular>
                  <StatusChip
                    label={t('hcp.status.Deleted')}
                    urgent={false}
                    bold
                  />
                </div>
              ) : employee.members[0].frozenAt ? (
                <div>
                  <BodyMediumRegular>
                    {t('admin.employee-detail.status')}
                  </BodyMediumRegular>
                  <StatusChip
                    label={t('hcp.status.Frozen')}
                    urgent={false}
                    bold
                  />
                </div>
              ) : (
                <>
                  <div>
                    <BodyMediumRegular>
                      {t('admin.employee-detail.pole-status')}
                    </BodyMediumRegular>
                    <StatusChip
                      label={t(
                        `hcp.status.${employee.hcpStatus?.status ?? 'Deleted'}`
                      )}
                      urgent={isUrgent(employee.hcpStatus)}
                      bold
                    />
                  </div>
                  <div>
                    <BodyMediumRegular>
                      {t('admin.employee-detail.card-status')}
                    </BodyMediumRegular>
                    <BodyMediumSemiBold>
                      {employee.members[0].manualMspHandover &&
                      employee.members[0].mspStatus === 'Sent'
                        ? t(`msp.status.Sent.manual`)
                        : t(`msp.status.${employee.members[0].mspStatus}`)}
                    </BodyMediumSemiBold>
                  </div>
                </>
              )}
            </div>
          </StTitleContainer>
          {currentTab === 'information' && !employee.deletedAt && (
            <StMenubarWrapper>
              <Menubar
                size="sm"
                title={t('admin.employee-detail.actions')}
                options={menuItem}
              />
            </StMenubarWrapper>
          )}
        </StHeader>

        {/* TABS */}
        <Tabs
          defaultValue={currentTab ?? 'information'}
          value={currentTab ?? 'information'}
          items={detailPages}
          setValue={(value) => {
            // We don't want to add a new entry to the history stack when changing tabs
            setSearchParameters((previous) => {
              previous.set('tab', value)

              return previous
            })
            setCurrentTab(value)
          }}
        />
      </StContainer>
      <DeleteEmployeeDialog
        employee={employee}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        handleDelete={handleDelete}
      />
      <FreezeEmployeeDialog
        employee={employee}
        open={freezeDialogOpen}
        setOpen={setFreezeDialogOpen}
        handleFreeze={handleFreeze}
        isLoading={isFreezing}
      />
      <UnfreezeEmployeeDialog
        employee={employee}
        open={unfreezeDialogOpen}
        setOpen={setUnfreezeDialogOpen}
        handleUnfreeze={handleUnfreeze}
        isLoading={isUnfreezing}
      />
    </ContentContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

const StHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const StTitleContainer = styled.div`
  & > div {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};

    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

    & > div {
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
    }
  }
`

const StMenubarWrapper = styled.div`
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
`
