import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'

type ChargingSessionInfoBannerProps = {
  title: string
  description: string
  type: 'info' | 'error' | 'warning'
  onResolveToggle?: () => void
  disabled?: boolean
}

export const ChargingSessionInfoBanner = ({
  title,
  description,
  type,
  onResolveToggle,
  disabled = false,
}: ChargingSessionInfoBannerProps) => {
  return (
    <StBanner $type={type}>
      {(type === 'error' || type === 'info') && (
        <StFlag
          onClick={disabled ? undefined : onResolveToggle}
          tabIndex={0}
          $type={type}
          $disabled={disabled}
        >
          <FontAwesomeIcon
            icon={type === 'info' ? ['fasr', 'flag'] : ['fass', 'flag']}
          />
        </StFlag>
      )}
      <StTitle $type={type}>
        <BodySmallRegular>{title}</BodySmallRegular>
      </StTitle>
      <StDescription>{description}</StDescription>
    </StBanner>
  )
}

const StBanner = styled.div<{ $type: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  background-color: ${({ theme, $type }) =>
    $type === 'error'
      ? theme.theme.colors['senary-4']
      : theme.theme.colors['nonary-9']};
  position: relative;
`

const StFlag = styled.button<{ $type: string; $disabled?: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.UI.SpacingPx.Space4};
  right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  color: ${({ theme, $type, $disabled }) =>
    $disabled
      ? theme.theme.text.body['gray-mid']
      : $type === 'error'
      ? theme.theme.colors.error
      : theme.theme.colors['nonary-0']};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  background: none;
  border: none;
`

const StTitle = styled.div<{ $type: string }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  color: ${({ theme, $type }) => {
    if ($type === 'error') {
      return theme.theme.colors.error
    }
    return theme.theme.colors['nonary-0']
  }};
  font-weight: 600;
`

const StDescription = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.theme.text.body.black};
`
