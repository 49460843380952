import { Capacitor } from '@capacitor/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconLayer } from 'deck.gl'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Map as MapBox, Marker } from 'react-map-gl'
import { styled, useTheme } from 'styled-components'
import { useDebounceValue, useMediaQuery } from 'usehooks-ts'

import { breakpoints } from '../../../../theme/layout/breakpoints'
import { BottomSheet } from '../../../components/bottom-sheet/BottomSheet'
import { AddressSearch } from '../../../components/form/address-search/AddressSearch'
import { createMapboxAddressString } from '../../../components/form/address-search/createAddressString'
import { useSafeAreaInsets } from '../../../core/hooks/useSafeAreaInsets'
import iconMapping from '../components/data/location-icon-mapping.json'
import iconAtlas from '../components/data/location_icon_atlas.png'
import { DeckGLOverlay } from '../components/DeckGlOverlay'
import { getIconName, IconClusterLayer } from '../components/icon-cluster-layer'
import {
  StCard,
  StMapContainer,
  StMobileLoader,
  StWebLoader,
} from '../components/Map'
import { UnAuthenticatedChargeLocationDetail } from '../components/UnAuthenticatedChargeLocationDetail'
import { useChargerLocationsPublic } from '../hooks/useChargerLocationsPublic'

import type { MapboxAddress } from '../../../components/form/address-search/types'
import type { Layer, PickingInfo } from 'deck.gl'
import type { MapRef } from 'react-map-gl'
import type { ClusterProperties } from 'supercluster'
import type { ChargingLocation, OptimileChargingPointCluster } from 'types'

const START_LOCATION = { latitude: 50.438696, longitude: 4.63357500000001 }
const CHARGER_ZOOM_LEVEL = 17
const shouldFlyToAnimate = !Capacitor.isNativePlatform()

export const UnauthenticatedMapScreen = () => {
  // -- State --
  const [zoom, setZoom] = useDebounceValue<number>(8, 50)
  const [bounds, setBounds] = useDebounceValue<string>('', 500)
  const [center, setCenter] = useDebounceValue<string>(
    JSON.stringify(START_LOCATION),
    50
  )
  const [detailInfo, setDetailInfo] = useState<ChargingLocation | null>(null)
  const [searchLocation, setSearchLocation] = useState<{
    longitude: number
    latitude: number
  }>()
  const [searchLocationString, setSearchLocationString] = useState('')
  const [showBackdrop, setShowBackdrop] = useState(false)

  // -- Ref --
  const mapRef = useRef<MapRef | null>(null)

  // -- Hooks --
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const { t } = useTranslation()
  const { top } = useSafeAreaInsets()
  const theme = useTheme()

  // -- Data --
  const { locations, clusters, loading } = useChargerLocationsPublic(
    JSON.parse(center),
    Math.round(zoom),
    bounds
  )

  const boundsArray = mapRef.current?.getBounds()?.toArray().flat()

  // This converts the mapbox bounds to a string that optimile can understand (long, lat are the other way around)
  useEffect(() => {
    boundsArray &&
      setBounds(
        [boundsArray[1], boundsArray[0], boundsArray[3], boundsArray[2]].join(
          ','
        )
      )
  }, [JSON.stringify(boundsArray)])

  // Close the detail info when the user presses the escape key
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setDetailInfo(null)
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [])

  // -- Vars --
  // Use a modified IconClusterLayer to add our own clustering when zoomed in enough
  const locationsLayer = new IconClusterLayer({
    data: locations,
    pickable: true,
    iconAtlas,
    iconMapping: iconMapping as unknown as string,
    id: 'icon-cluster',
    sizeScale: 40,
    selectedId: detailInfo?.id,
  }) as unknown as Layer

  // Use a basic IconLayer to show the optimile clusters
  const clustersLayer = new IconLayer({
    id: 'icon-layer',
    data: clusters,
    pickable: true,
    getPosition: (d) => [d.longitude, d.latitude],
    sizeScale: 30,
    iconAtlas,
    iconMapping,
    getIcon: (d) => getIconName(d.number_of_locations, d.status),
    getSize: (d) => {
      if (d.number_of_locations === 1) return 0
      return Math.min(100, d.number_of_locations) / 100 + 1
    },
  })

  // Choose the right layer based on zoom level
  const layers = [zoom > 11 ? [locationsLayer] : [clustersLayer]]

  // -- Handlers --
  const handleClick = (
    info: PickingInfo<
      (ChargingLocation & ClusterProperties) | OptimileChargingPointCluster,
      { expansionZoom: number }
    >
  ) => {
    if (
      info.object &&
      (info.object as OptimileChargingPointCluster)?.number_of_locations
    ) {
      // This is a optimile cluster
      // We don't have the expansion zoom available here, so we just zoom in a bit
      return mapRef.current?.flyTo({
        center: { lat: info.object.latitude, lng: info.object.longitude },
        zoom: zoom + 2,
        animate: shouldFlyToAnimate,
      })
    }

    if (info.object && !(info.object as ClusterProperties).cluster) {
      // This is a marker with a single location

      const currentZoom = mapRef.current?.getZoom() || CHARGER_ZOOM_LEVEL
      const zoom =
        currentZoom > CHARGER_ZOOM_LEVEL ? currentZoom : CHARGER_ZOOM_LEVEL

      mapRef.current?.flyTo({
        center: { lat: info.object.latitude, lng: info.object.longitude },
        zoom,
        animate: shouldFlyToAnimate,
      })

      setDetailInfo(info.object as ChargingLocation)
    }

    if (info.object && (info.object as ClusterProperties).cluster) {
      // This is our own cluster

      // Supercluster provides the expansion zoom, we use it to zoom in until the cluster is expanded into multiple markers / clusters
      mapRef.current?.flyTo({
        center: { lat: info.coordinate![1], lng: info.coordinate![0] },
        zoom: info.expansionZoom,
        animate: shouldFlyToAnimate,
      })
    }
  }

  // -- Handlers --
  const handleSelectAddress = (address: MapboxAddress | null) => {
    if (!address) return

    setDetailInfo(null)

    if (address.features[0].properties.bbox) {
      mapRef.current?.fitBounds(address.features[0].properties.bbox)
    } else {
      mapRef.current?.flyTo({
        center: {
          lat: address.features[0].geometry.coordinates[1],
          lng: address.features[0].geometry.coordinates[0],
        },
        zoom: 17,
        animate: shouldFlyToAnimate,
      })
    }

    setSearchLocation({
      latitude: address.features[0].geometry.coordinates[1],
      longitude: address.features[0].geometry.coordinates[0],
    })

    const name = address.features[0].properties.name
    const country = address.features[0].properties.context.country?.name || ''
    const addressString = createMapboxAddressString(
      address.features[0].properties.context
    )

    const hasAddress = addressString.trim().length > 0

    setSearchLocationString(
      `${hasAddress ? addressString : name}${
        country === name ? '' : `, ${country}`
      }`
    )
  }

  const handleClose = () => {
    setDetailInfo(null)
  }

  // -- Render --
  return (
    <>
      <StMapContainer $isImpersonating={false}>
        <MapBox
          dragRotate={false}
          touchPitch={false}
          pitchWithRotate={false}
          ref={mapRef}
          initialViewState={{ ...START_LOCATION, zoom: 8 }}
          onZoom={(event) => {
            setZoom(event.viewState.zoom)
            setCenter(
              JSON.stringify({
                latitude: event.viewState.latitude,
                longitude: event.viewState.longitude,
              })
            )
          }}
          onDrag={(event) => {
            setCenter(
              JSON.stringify({
                latitude: event.viewState.latitude,
                longitude: event.viewState.longitude,
              })
            )
          }}
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          style={{
            width: '100dvw',
            height: '100vh',
          }}
          mapStyle="mapbox://styles/bothrsdev/cloy3aalb013d01qo1geifax9"
        >
          <DeckGLOverlay
            interleaved
            layers={layers}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onClick={handleClick}
            getCursor={({ isHovering }) => (isHovering ? 'pointer' : 'grab')}
          />

          {searchLocation ? (
            <Marker
              anchor="bottom"
              latitude={searchLocation.latitude}
              longitude={searchLocation.longitude}
            >
              <FontAwesomeIcon
                icon={['fass', 'location-dot']}
                fontSize={30}
                color={theme.theme.colors['senary-1']}
              />
            </Marker>
          ) : null}

          {loading && isDesktop && (
            <StWebLoader src="/assets/map-loader-web.lottie" autoplay loop />
          )}
          {loading && !isDesktop && !detailInfo && (
            <StMobileLoader
              src="/assets/map-loader-mobile.lottie"
              autoplay
              loop
            />
          )}
        </MapBox>
      </StMapContainer>

      <StContentContainer>
        <AddressSearch
          name="addressSearch"
          showIcon
          clearable
          sharp={false}
          displayValue={searchLocationString}
          onSelect={handleSelectAddress}
          placeholder={t('map.search.placeholder')}
          types={['address', 'city', 'country', 'postcode', 'street']}
        />

        {detailInfo && isDesktop && (
          <StCard>
            <UnAuthenticatedChargeLocationDetail
              detailInfo={detailInfo}
              onClose={handleClose}
            />
          </StCard>
        )}

        {!isDesktop && (
          <BottomSheet
            isOpen={!!detailInfo}
            onClose={() => setDetailInfo(null)}
            title={detailInfo?.name}
            snapPoints={
              Capacitor.isNativePlatform()
                ? [-(34 + top), 200, 0]
                : [-34, 200, 0]
            }
            onSnap={(index) => {
              setShowBackdrop(index === 0)
            }}
            initialSnap={1}
            hasBackdrop={showBackdrop}
            loading={loading}
          >
            {detailInfo && (
              <UnAuthenticatedChargeLocationDetail
                detailInfo={detailInfo}
                onClose={handleClose}
              />
            )}
          </BottomSheet>
        )}
      </StContentContainer>
    </>
  )
}

export const StContentContainer = styled.div`
  pointer-events: none;

  margin: 0 auto;

  width: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space10});
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.navigation};

  position: absolute;
  top: calc(
    ${({ theme }) => theme.UI.SpacingPx.Space4} + var(--inset-top, 0px)
  );
  left: 0;
  right: 0;

  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-auto-rows: 100%;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

  & > div:only-child {
    grid-column: 1 / -1;
  }

  & > div {
    pointer-events: auto;
  }

  @media ${breakpoints.desktop} {
    top: ${({ theme }) => theme.UI.SpacingPx.Space10};

    max-height: calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space26});
  }
`
