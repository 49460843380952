import { startOfMonth, endOfMonth, subDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EmployerRoutes } from '../../../../routing/routes'
import { DataTable } from '../../../dataTable/components/DataTable'
import { useDataTable } from '../../../dataTable/hooks/useDataTable'
import { Filters } from '../../../employee/onboarding/components/Filters'

import {
  useAnomaliesColumns,
  useAnomaliesFilters,
} from './anomalies/AnomaliesTableConfig'
import { StHeader } from './UsageDataTable'

import type { AnomalyWithRelations } from '../types/types'

export const AnomaliesDataTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    filterValues,
    setFilterValues,
    clearFilters,
    navigationContainerBottom,
  } = useDataTable<AnomalyWithRelations>()

  const filters = useAnomaliesFilters(true)
  const columns = useAnomaliesColumns(true)

  const handleRowClick = (anomaly: AnomalyWithRelations) => {
    if (
      anomaly.member?.user?.blossomId &&
      anomaly.chargingSessionId &&
      anomaly.timestamp
    ) {
      let url = `${EmployerRoutes.Employees}/${anomaly.member.user.blossomId}?tab=usage`

      const anomalyDate = new Date(anomaly.timestamp)
      const today = new Date()
      const thirtyDaysAgo = subDays(today, 30)

      // If the anomaly is older than 30 days, add date params to URL
      if (anomalyDate < thirtyDaysAgo) {
        // Use the month containing the anomaly
        const monthStart = startOfMonth(anomalyDate)
        const monthEnd = endOfMonth(anomalyDate)

        // Add date parameters to URL
        url += `&dateFrom=${monthStart.toISOString()}&dateTo=${monthEnd.toISOString()}`
      }

      // Navigate with state for opening the drawer
      navigate(url, {
        state: {
          openSessionDrawer: true,
          chargingSessionId: anomaly.chargingSessionId,
        },
      })
    }
  }

  return (
    <>
      <StHeader>
        <Filters
          key="anomalies-data-table-filters"
          filters={filters}
          filterValues={filterValues}
          withSearch
          searchPlaceholder={t('employer.usage.table.filter.search')}
          onFiltersChange={(filterKey, value) => {
            setFilterValues((oldValues) => {
              return {
                ...oldValues,
                [filterKey]: value,
              }
            })
          }}
          onClearFilters={() => {
            clearFilters()
          }}
          loading={false}
          stickyPosition={navigationContainerBottom}
        />
      </StHeader>
      <DataTable<AnomalyWithRelations>
        columns={columns}
        emptyTitle={t('employer.anomalies.table.empty-title')}
        emptyDescription={t('employer.anomalies.table.empty-description')}
        emptyFiltersTitle={t('employer.anomalies.table.empty-title')}
        emptyFiltersDescription={t(
          'employer.anomalies.table.empty-description'
        )}
        onRowClick={handleRowClick}
      />
    </>
  )
}
