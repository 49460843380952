import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { BodySmallRegular } from '../../../components/typography'
import { SelectedWheelSlide, Wheel } from '../../../components/Wheel'

type ChargingSpeedPickerProps = {
  value: number
  onChange: (newSpeed: number) => void
  maxSpeed?: number
}

export function ChargingSpeedPicker({
  value,
  onChange,
  maxSpeed = 32,
}: ChargingSpeedPickerProps) {
  const { t } = useTranslation()

  const minSpeed = 6 // Default min speed is 6A
  const effectiveMaxSpeed = Math.max(minSpeed, maxSpeed)
  const speedStep = 1

  return (
    <StWheelWrapper>
      <StSelectedWheelSlide>
        <StBodySmallRegular>
          {t('employee.profile.charger.speed.unit')}
        </StBodySmallRegular>
      </StSelectedWheelSlide>
      <StWheel
        length={effectiveMaxSpeed}
        width={140}
        perspective="center"
        formatValue={(relative) => `${minSpeed + speedStep * relative}`}
        initialIndex={(value - minSpeed) / speedStep}
        onChange={(wheelIndex) => onChange(minSpeed + speedStep * wheelIndex)}
      />
    </StWheelWrapper>
  )
}

const StWheelWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const StSelectedWheelSlide = styled(SelectedWheelSlide)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StWheel = styled(Wheel)`
  flex: 1;
  height: 200px;
`

const StBodySmallRegular = styled(BodySmallRegular)`
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
