const rootUrl = import.meta.env.VITE_BACKEND_URL

export const Urls = {
  Root: `${rootUrl}`,
  AppSettings: `${rootUrl}/app-settings`,
  OptimileStatus: `${rootUrl}/health/optimile`,
  Translations: `${rootUrl}/translations`,

  // User
  User: `${rootUrl}/users/current`,
  Invite: `${rootUrl}/users/invite`,
  UserMemberships: `${rootUrl}/users/memberships`,

  // Intercom
  IntercomHashes: `${rootUrl}/intercom/hash`,

  // Status
  Status: `${rootUrl}/users/status`,

  // Onboarding
  Onboarding: `${rootUrl}/onboarding`,
  PersonalData: `${rootUrl}/onboarding/personal-data`,
  BankingData: `${rootUrl}/onboarding/banking-data`,
  SelectKeys: `${rootUrl}/onboarding/select-keys`,
  ChargerLocation: `${rootUrl}/onboarding/charger-location`,
  PrivateTerrain: `${rootUrl}/onboarding/private-terrain`,
  OnboardingData: `${rootUrl}/onboarding/onboarding-data`,
  OnboardingImages: `${rootUrl}/onboarding/images/:key`,
  DeleteOnboardingImage: `${rootUrl}/onboarding/images/:key/:imageId`,
  OnboardingImagesPolling: `${rootUrl}/onboarding/images`,
  SelectSolution: `${rootUrl}/onboarding/select-solution`,
  InstallationAddress: `${rootUrl}/onboarding/installation-address`,

  Onboardingtoken: `${rootUrl}/onboarding/token`,
  TokenFlow: `${rootUrl}/onboarding/token/onboarding`,
  TokenOnboardingImages: `${rootUrl}/onboarding/token/images/:key`,
  TokenDeleteOnboardingImage: `${rootUrl}/onboarding/token/images/:key/:imageId`,
  TokenOnboardingImagesPolling: `${rootUrl}/onboarding/token/images`,
  SetSkippedExtraImages: `${rootUrl}/onboarding/token/skipped-extra-images`,
  FinalizeOnboarding: `${rootUrl}/onboarding/finalize`,

  SendVerificationEmail: `${rootUrl}/employees/verify-email`,

  // Product
  productAll: `${rootUrl}/products`,

  // Zoho
  ZohoPhoto: `${rootUrl}/zoho/photo`,
  DocumentFile: `${rootUrl}/zoho/file`,

  // Employer
  fetchEmployees: `${rootUrl}/employees`,
  inviteEmployees: `${rootUrl}/employees`,
  employeeDetail: `${rootUrl}/employees/:blossomId`,
  employeeUpdate: `${rootUrl}/employees/:id`,
  employeeUpdatePracticalData: `${rootUrl}/employees/practical/:id`,
  employeeDelete: `${rootUrl}/employees/:id`,
  employeeFreeze: `${rootUrl}/employees/:id/freeze`,
  employeeUnfreeze: `${rootUrl}/employees/:id/unfreeze`,
  employeeHcpInvite: `${rootUrl}/employees/hcp/:memberId`,
  employeeRequestMsp: `${rootUrl}/employees/msp/:memberId`,
  employeeChangeMspContract: `${rootUrl}/employees/msp/contract/:memberId`,
  employeeManualMspHandover: `${rootUrl}/employees/msp-handover/:memberId`,
  employeeResendInvite: `${rootUrl}/employees/resend-invite`,

  // Company
  updateFinancialSettings: `${rootUrl}/company/financial`,
  fetchAllCompanies: `${rootUrl}/company/all`,

  // Contract
  contractData: `${rootUrl}/contracts/overview`,

  // Charging session
  employerChargingReports: `${rootUrl}/charging-session/employer`,
  employeeChargingSessions: `${rootUrl}/charging-session/employee`,
  employerChargingSessionsStatistics: `${rootUrl}/charging-session/employer/statistics`,
  employerChargingSessionsAverages: `${rootUrl}/charging-session/employer/averages`,
  employerChargingSessionsExport: `${rootUrl}/charging-session/employer-export`,
  employeeChargingSessionsExport: `${rootUrl}/charging-session/employee-export`,
  employerChargingAnomalies: `${rootUrl}/anomalies`,
  employerChargingAnomalyResolve: `${rootUrl}/anomalies/:id/resolve`,

  // Charger
  employeeChargers: `${rootUrl}/optimile/devices`,
  employeeChargerConfiguration: `${rootUrl}/optimile/devices/:id/configuration`,

  // Documents
  fetchDocuments: `${rootUrl}/documents`,
  markDocumentAsRead: `${rootUrl}/documents/:id/read`,
  unreadDocumentsCount: `${rootUrl}/documents/unread-count`,

  // Invoices
  employerInvoices: `${rootUrl}/invoice/employer`,
  invoiceDetail: `${rootUrl}/invoice/:id`,
  invoiceLines: `${rootUrl}/invoice/:id/lines`,
  downloadInvoicePdf: `${rootUrl}/invoice/file/:id`,

  // Reimbursements
  reimbursements: `${rootUrl}/reimbursement`,
  reimbursementLines: `${rootUrl}/reimbursement/lines/:id`,
  fetchReimbursementFileId: `${rootUrl}/reimbursement/file-id`,

  // Map
  chargingLocations: `${rootUrl}/optimile/charging-locations`,
  publicChargingLocations: `${rootUrl}/optimile-public/charging-locations`,
  chargingMarkers: `${rootUrl}/optimile/charging-locations/clusters`,
  publicChargingMarkers: `${rootUrl}/optimile-public/charging-locations/clusters`,
  chargingLocationDetail: `${rootUrl}/optimile/charging-location/:id`,
  publicChargingLocationDetail: `${rootUrl}/optimile-public/charging-location/:id`,

  // Employee charging sessions
  employeeHcpChargingSessions: `${rootUrl}/charging-session/employee/hcp`,
  employeeMspChargingSessions: `${rootUrl}/charging-session/employee/msp`,
  employeeRecentChargingSessions: `${rootUrl}/charging-session/employee/recent`,

  // Hcp Quotes
  getQuoteByMemberId: `${rootUrl}/quotes/:memberId`,
  approveQuote: `${rootUrl}/quotes/approve/:memberId`,

  // Charge card
  activateChargeCard: `${rootUrl}/users/activate-msp-card`,
  blockChargeCard: `${rootUrl}/users/block-msp-card`,
  uploadDamageProof: `${rootUrl}/users/upload-damage-proof`,

  // Push notifications
  subscribeToPush: `${rootUrl}/push-notifications/subscribe`,

  // HEMS
  fetchInstallation: `${rootUrl}/hems`,
  updateInstallation: `${rootUrl}/hems`,
  fetchChargerSetPoints: `${rootUrl}/hems/set-points`,
  fetchEnergyProduction: `${rootUrl}/hems/energy-production`,
  fetchEnergyConsumption: `${rootUrl}/hems/energy-consumption`,
  fetchEnergyTips: `${rootUrl}/hems/energy-tips`,
  fetchEnergySavings: `${rootUrl}/hems/energy-savings`,
  SetChargerSetPoints: `${rootUrl}/hems/set-points`,
  fetchUsageDetails: `${rootUrl}/hems/usage-details`,
  startHemsOnboarding: `${rootUrl}/hems/onboarding/start`,
  connectHems: `${rootUrl}/hems/onboarding/connect`,
  completeOnboarding: `${rootUrl}/hems/onboarding/configure`,
  completeIntroduction: `${rootUrl}/hems/onboarding/introduction`,
  fetchHomeChargingSession: `${rootUrl}/hems/home-charging-session`,
  getDongleStatus: `${rootUrl}/hems/dongle-status`,
  fetchDynamicPrice: `${rootUrl}/hems/dynamic-price`,

  // Impersonate
  impersonateEmployee: `${rootUrl}/employees/impersonate/:employeeId`,
}
