import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { keyframes, styled } from 'styled-components'

import { useMember } from '../../member/hooks/useMember'
import { H5 } from '../typography'

import type { ReactNode } from 'react'

type DrawerContentProps = {
  children: ReactNode
  onClose: () => void
  title?: string
  /** Give content same padding as title. Enabled by default */
  contentPadding?: boolean
  headerBackground?: string
}

export const DrawerContent = ({
  children,
  onClose,
  title,
  contentPadding = true,
  headerBackground,
}: DrawerContentProps) => {
  const { isImpersonating } = useMember()

  return (
    <DialogPrimitive.Portal>
      <StOverlay />
      <StContent
        onEscapeKeyDown={onClose}
        onPointerDownOutside={onClose}
        $contentPadding={contentPadding}
        $isImpersonating={isImpersonating}
      >
        <StHeader
          $contentPadding={contentPadding}
          $headerBackground={headerBackground}
        >
          <H5>{title}</H5>
          <StClose onClick={onClose}>
            <StCloseIcon icon={['fasl', 'xmark']} fontSize={24} />
          </StClose>
        </StHeader>
        {children}
      </StContent>
    </DialogPrimitive.Portal>
  )
}

export const Drawer = DialogPrimitive.Root
export const DrawerTrigger = DialogPrimitive.Trigger
export const DrawerClose = DialogPrimitive.Close
export const DrawerTitle = DialogPrimitive.Title
export const DrawerDescription = DialogPrimitive.Description

const StOverlay = styled(DialogPrimitive.Overlay)`
  position: fixed;
  z-index: 505;
  background-color: ${({ theme }) => theme.theme.colors['secondary-1']};
  opacity: 0.8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const slideIn = keyframes({
  from: { transform: 'translate3d(100%, 0, 0)' },
  to: { transform: 'translate3d(0,0,0)' },
})

const slideOut = keyframes({
  from: { transform: 'translate3d(0,0,0)' },
  to: { transform: 'translate3d(100%, 0, 0)' },
})

const StContent = styled(DialogPrimitive.Content)<{
  $contentPadding: boolean
  $isImpersonating: boolean
}>`
  position: fixed;
  top: ${({ $isImpersonating }) => ($isImpersonating ? '52px' : '0')};
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.theme.colors.white};
  width: 460px;
  z-index: 505;
  transform: translate3d((100%, 0, 0));
  padding: ${({ theme, $contentPadding }) =>
    $contentPadding
      ? `${theme.UI.SpacingPx.Space8} ${theme.UI.SpacingPx.Space10}`
      : undefined};

  height: 100%;
  overflow: scroll;

  &[data-state='open'] {
    animation: ${slideIn} 150ms cubic-bezier(0.22, 1, 0.36, 1);
  }

  &[data-state='closed'] {
    animation: ${slideOut} 150ms cubic-bezier(0.22, 1, 0.36, 1);
  }
`

const StHeader = styled.div<{
  $contentPadding: boolean
  $headerBackground?: string
}>`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ $contentPadding, theme }) =>
    $contentPadding
      ? undefined
      : `padding: ${theme.UI.SpacingPx.Space6};
  padding-bottom: 0;
  padding-top: ${theme.UI.SpacingPx.Space8};
  background-color: ${theme.theme.colors['nonary-10']};`}

  ${({ $headerBackground }) =>
    $headerBackground ? `background-color: ${$headerBackground};` : undefined}

  h5 {
    margin: 0;
  }
`

const StClose = styled(DialogPrimitive.Close)`
  background: none;
  border: none;
  cursor: pointer;
`

const StCloseIcon = styled(FontAwesomeIcon)``
