import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import { styled, useTheme } from 'styled-components'
import { useLocalStorage, useMediaQuery } from 'usehooks-ts'

import { LocalStorageKeys } from '../../config/constants/local-storage-keys'
import { BottomNavigation } from '../../modules/components/navigation/BottomNavigation'
import { Navigation } from '../../modules/components/navigation/Navigation'
import { EmployeeProfileMenu } from '../../modules/employee/profile/components/EmployeeProfileMenu'
import { useMember } from '../../modules/member/hooks/useMember'
import { ImpersonationBanner } from '../../modules/navigation/components/ImpersonationBanner'
import { useUser } from '../../modules/user/hooks/useUser'
import { breakpoints } from '../../theme/layout/breakpoints'
import { EmployeeRoutes } from '../routes'

import type {
  NavigationItemType,
  NavigationOptions,
} from '../../modules/components/navigation/Navigation'
type EmployeeNavigationWrapperProps = {
  hideMobile?: boolean
}

export const EmployeeNavigationWrapper = ({
  hideMobile,
}: EmployeeNavigationWrapperProps) => {
  // -- Hooks --
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(breakpoints.desktop)
  const location = useLocation()
  const theme = useTheme()
  const { user } = useUser()
  const { isImpersonating, setCurrentMember } = useMember()
  const [originalSuperAdminId] = useLocalStorage(
    LocalStorageKeys.SUPERADMIN_MEMBER,
    ''
  )

  // -- Vars --
  const optionalItems: NavigationItemType[] = isDesktop
    ? []
    : [
        {
          name: t('employee.navigation.profile'),
          url: EmployeeRoutes.Profile,
          icon: 'circle-user',
        },
      ]

  const employeeNavigationOptions: NavigationOptions = {
    navigationItems: [
      {
        name: t('employee.navigation.home'),
        url: EmployeeRoutes.Root,
        icon: 'home',
      },
      {
        name: t('employee.navigation.usage'),
        url: EmployeeRoutes.Usage,
        icon: 'bolt',
      },
      user.addresses[0]?.scoptId && user.addresses[0].scoptOnboarded
        ? {
            name: t('employee.navigation.hems'),
            url: EmployeeRoutes.HEMS,
            icon: 'plug',
          }
        : undefined,
      isDesktop
        ? {
            name: t('employee.navigation.refunds'),
            url: EmployeeRoutes.Refunds,
          }
        : undefined,
      {
        name: t('employee.navigation.map'),
        url: EmployeeRoutes.Map,
        icon: 'location-dot',
      },
      ...optionalItems,
    ].filter(Boolean) as NavigationItemType[],
    rightItem: <EmployeeProfileMenu />,
    logo: true,
    backgroundColor:
      location.pathname === EmployeeRoutes.Root
        ? theme.theme.colors['primary-3']
        : undefined,
  }

  function handleReturnToSuperAdmin() {
    if (originalSuperAdminId) {
      setCurrentMember(originalSuperAdminId)
      window.location.reload() // Reload to ensure the member is in the correct context
    }
  }

  return (
    <StContainer>
      {isImpersonating && (
        <ImpersonationBanner onEndSession={handleReturnToSuperAdmin} />
      )}
      {isDesktop ? <Navigation options={employeeNavigationOptions} /> : null}

      <Outlet />

      {isDesktop || hideMobile ? null : (
        <BottomNavigation
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          navigationItems={employeeNavigationOptions.navigationItems!}
        />
      )}
    </StContainer>
  )
}

const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
`
